export const phoneList = [
  130,
  131,
  132,
  133,
  134,
  135,
  136,
  137,
  138,
  139,
  140,
  141,
  142,
  143,
  144,
  145,
  146,
  147,
  148,
  149,
  150,
  151,
  152,
  153,
  155,
  156,
  157,
  158,
  159,
  166,
  167,
  170,
  171,
  172,
  173,
  175,
  176,
  177,
  178,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,
  189
]

export const namesList = [
  '赵',
  '钱',
  '孙',
  '李',
  '周',
  '吴',
  '郑',
  '王',
  '冯',
  '陈',
  '褚',
  '卫',
  '蒋',
  '沈',
  '韩',
  '杨',
  '朱',
  '秦',
  '尤',
  '许',
  '何',
  '吕',
  '施',
  '张',
  '孔',
  '曹',
  '严',
  '华',
  '金',
  '魏',
  '陶',
  '姜',
  '戚',
  '谢',
  '邹',
  '喻',
  '柏',
  '水',
  '窦',
  '章',
  '云',
  '苏',
  '潘',
  '葛',
  '奚',
  '范',
  '彭',
  '郎',
  '鲁',
  '韦',
  '昌',
  '马',
  '苗',
  '凤',
  '花',
  '方',
  '俞',
  '任',
  '袁',
  '柳',
  '酆',
  '鲍',
  '史',
  '唐',
  '费',
  '廉',
  '岑',
  '薛',
  '雷',
  '贺',
  '倪',
  '汤',
  '滕',
  '殷',
  '罗',
  '毕',
  '郝',
  '邬',
  '安',
  '常',
  '乐',
  '于',
  '时',
  '傅',
  '皮',
  '卞',
  '齐',
  '康',
  '伍',
  '余',
  '元',
  '卜',
  '顾',
  '孟',
  '平',
  '黄',
  '和',
  '穆',
  '萧',
  '尹'
]