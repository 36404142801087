import { useRef, useEffect } from 'react'

/**
 * 第一次执行函数，和第一次之后执行的函数
 * @param {function} firstFn 第一次执行的函数
 * @param {function} afterFn 第一次之后执行的函数
 * @param {Array} useEffectRelyOn useEffect的依赖数组
 */
function useFirstOrAfter(firstFn: () => void, afterFn: () => void, useEffectRelyOn:ReadonlyArray<unknown>) {
  const isFirst = useRef(true)
  useEffect(() => {
    if (isFirst.current) {
      firstFn()
      isFirst.current = false
    } else {
      afterFn()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectRelyOn)
}

export default useFirstOrAfter