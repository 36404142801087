/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import Header from '@/components/header/header'
import styles from './jkr-general-agreement.module.scss'
import classNames from 'classnames'
import { getParams } from '@bihu/common-js'
import indexApi from '@/apis/index'
import { encryptString } from '@/utils/utils'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface Props{ }

export interface LoginInfo {
  uid: string,
  realName: string,
  mobile: string,
  loginAt:string
}
// 聚客融通用授权协议
const JukerongGeneralAgreement:React.FC<Props> = props => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const [isShowHeader, setIsShowHeader] = useState(false)
  const [uid, setUid] = useState<string>('')
  const [loginInfo, setLoginInfo] = useState<any>()
  const [company, setCompany] = useState('')

  // 获取用户信息
  const getLoginInfo = async() => {
    const res = await indexApi.getLoginUserInfo()
    setLoginInfo(res)
  }

  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
    if (getParams(undefined, 'useInfo')) {
      let useInfo = JSON.parse(getParams(undefined, 'useInfo')!)
      setUid(useInfo?.uid)
      setLoginInfo(useInfo)
    } else {
      getLoginInfo()
    }
    if (getParams(undefined, 'company')) {
      setCompany(getParams(undefined, 'company')!)
    }
  }, [])

  // 有钱钱包注册协议
  const youqianlaiRegister = () => {
    return (
      <>
        <h2 style={{ textAlign: 'center' }}>授权书</h2>
        <div className={styles.emptyLine}></div>
        <p>特别提示:</p>
        <p>
        为了保障您的合法权益，请您务必审慎阅读并充分理解本授权书的全部内容，特别是以加粗形式提示您注意的条款。您在展示给您的页面上点击/勾选 “确认”、“同意”或类似表明签约意愿文字的按钮，即表示您确认接受并签署本授权书，同意受到本授权书所有条款的约束。如您不接受本授权书部分或全部内容，请您立即停止使用本服务。
        </p>
        <div className={styles.emptyLine}></div>
        <p>特别提示:</p>
        <p className={styles.bold}>
          为了向您提供本服务，{ channelValue?.includes('kuaishou') ? '我们将收集' : `您明确授权本平台向本平台的合作机构（${company}提供` }您的姓名、年龄、所在城市、申请额度、手机号、信用情况、工作年限、收入情况、房产信息、车辆信息、社保及公积金信息、商业保险信息、企业主信息、纳税信息，具体以您实际向本平台提供的为准。同时您同意并授权该合作机构接收您的上述个人信息并可将该信息提供给其为您提供贷款服务的合作方。您同意并授权该合作机构及其合作方可将您的个人信息进行收集、留存、整理、加工，用于为您提供贷款推荐或贷款服务。
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>
        您已经充分认识到您的财产性信息对您而言是私密且重要的信息（本授权书所述的财产性信息包括您的收入情况、房产信息、车辆信息、社保及公积金信息、商业保险信息）。您已经充分理解并知悉提供和使用财产性信息的风险，这些风险包括但不限于：纳入这些信息对您的信用评级（评分）、信用报告等结果可能产生不利影响，该等信息被被授权人依法提供给第三方后被他人不当利用的风险，因您的信用状况较好而造成您被第三方推销产品或服务等打扰的风险等。
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>
        您同意该合作机构及其合作方或其员工可通过电话、手机短信等方式与您取得联系，向您告知或发送服务相关信息及其他商业电子信息，并为保证服务质量对上述电话进行录音。您在此授权并自愿成为该合作机构的注册用户，并接受该合作机构平台上展示的注册协议及隐私政策的约束，并可通过直接登录该合作机构平台接受其提供的服务。
        </p>
        {
          uid && <>
            <div className={styles.emptyLine}></div>
            <p className={styles.bold}>您的登录账号:  {encryptString(loginInfo?.mobile, 3, 4) }</p>
            <div className={styles.emptyLine}></div>
            <p className={styles.bold}>uid:  {loginInfo?.uid}</p>
            <div className={styles.emptyLine}></div>
          </>
        }
      </>
    )
  }


  return (
    <div className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title="通用授权协议" />
      }
      {
        youqianlaiRegister()
      }
    </div>
  )
}

export default JukerongGeneralAgreement