// 下载
import React, { useEffect, useState } from 'react'
import styles from './download.module.scss'
import activityApi from '@/apis/activity'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { isIOS, isInWeChat, isMobile, download } from '@bihu/common-js'
import classnames from 'classnames'
import Icon from '@/components/icon/icon'
interface Props {}

const Download: React.FC<Props> = () => {
  const hostNameObj = getHostNameObj()
  // 安卓下载链接
  const [androidUrl, setAndroidUrl] = useState('')
  // ios下载链接
  const [iosUrl, setIosUrl] = useState('')

  useEffect(() => {
    // 获取app下载url
    async function getAppDownloadUrl() {
      try {
        const res = await activityApi.getNewestVersion({
          appType: hostNameObj.appType
        })
        setAndroidUrl(res.androidPackageLocation)
        setIosUrl(res.iosPackageLocation)
        downloadApp(res.androidPackageLocation, res.iosPackageLocation)
      } catch (error) {
        // Toast.show({
        //   content: JSON.stringify(error),
        //   duration: 3000
        // })
      }
    }
    getAppDownloadUrl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 下载文件
  const downloadApp = (androidDownLoadUrl:string, iosDownLoadUrl:string) => {

    if (isIOS) {
      // download('http://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/app_download/%E6%96%B0%E7%89%88%E5%85%83%E4%BF%A1%E9%92%B1%E5%8C%85.ipa', '元信', 'ipa')
      window.location.href = iosDownLoadUrl

    } else {
      download(androidDownLoadUrl, '有钱来', 'apk')
    }

  }

  return (
    <div></div>
  // <div className={styles.download}>

  //   <div className={styles.logoBox}>
  //     <img className={styles.logo} src={hostNameObj.logo} alt="logo" />
  //     {hostNameObj.appName}
  //   </div>

  //   <div className={styles.main}>
  //     <div className={styles.advantageBox}>
  //       <div className={styles.item}>
  //         <div className={styles.left}>
  //           <Icon type="bulb" className={styles.icon} />
  //         </div>
  //         <div className={styles.right}>
  //           <div className={styles.title}>借的到</div>
  //           <div className={styles.description}>多种借款方式成功率高</div>
  //         </div>
  //       </div>
  //       <div className={styles.item}>
  //         <div className={styles.left}>
  //           <Icon type="rocket" className={styles.icon} />
  //         </div>
  //         <div className={styles.right}>
  //           <div className={styles.title}>放款快</div>
  //           <div className={styles.description}>当日即可完成评估</div>
  //         </div>
  //       </div>
  //       <div className={styles.item}>
  //         <div className={styles.left}>
  //           <Icon type="purse" className={styles.icon} />
  //         </div>
  //         <div className={styles.right}>
  //           <div className={styles.title}>额度高</div>
  //           <div className={styles.description}>额度最高可达20万</div>
  //         </div>
  //       </div>
  //       <div className={styles.item}>
  //         <div className={styles.left}>
  //           <Icon type="gold" className={styles.icon} />
  //         </div>
  //         <div className={styles.right}>
  //           <div className={styles.title}>利息低</div>
  //           <div className={styles.description}>最低可达0.2%</div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className={classnames(styles.downloadBtn)} onClick={downloadApp}>
  //     点击下载APP
  //     </div>

  //     <div className={styles.features}>
  //       <div className={styles.item}>
  //         <div className={styles.iconBox}>
  //           <Icon type="safety" className={styles.icon} />
  //         </div>
  //         <div>专业合规</div>
  //       </div>
  //       <div className={styles.item}>
  //         <div className={styles.iconBox}>
  //           <Icon type="money-collect" className={styles.icon} />
  //         </div>
  //         <div>息费透明</div>
  //       </div>
  //       <div className={styles.item}>
  //         <div className={styles.iconBox}>
  //           <Icon type="lock" className={styles.icon} />
  //         </div>
  //         <div>隐私加密</div>
  //       </div>
  //     </div>

  //     <div className={styles.tip}>本平台是金融机构第三方互联网营销平台</div>
  //     <div className={styles.tip}>为客户提供贷款推荐和咨询服务</div>
  //     <div className={styles.tip}>放款由第三方金融机构提供</div>
  //     <div className={styles.tip}>请根据个人能力合理贷款，理性消费，避免逾期</div>
  //     <div className={styles.tip}>所有贷款申请在未成功贷款前绝不收取任何费用，为了保证您的
  //     </div>
  //     <div className={styles.tip}>资金安全，请不要相信任何要求您支付费用的信息、</div>
  //     <div className={styles.tip}>邮件、电话等不实信息。</div>
  //     <div className={styles.tip}>
  //       {hostNameObj.filing}
  //     </div>
  //   </div>
  // </div>
  )
}

export default Download
