import { useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { setAdChannelCode, checkSpecialChannel } from '@/store/adChannelCode'
import type { AppDispatch, RootState } from '@/store'

export const useInitializeAdChannelCode = () => {
  const [search] = useSearchParams()
  const dispatch = useDispatch<AppDispatch>()
  const { adChannelCode } = useSelector((state: RootState) => state.channel)
  const navigator = useNavigate()

  useEffect(() => {
    const newAdChannelCode = search.get('adChannelCode') || search.get('source') || localStorage.getItem('ad_channel_code') || ''
    localStorage.setItem('ad_channel_code', newAdChannelCode)
    dispatch(setAdChannelCode(newAdChannelCode))
    dispatch(checkSpecialChannel())
  }, [dispatch, search, navigator])
}