// 审核新闻页面相关接口
import { api } from '@/utils/axios'

interface GetListParams{
  url: string
}

const auditNewApi = {
  // 获取每日新闻信息
  getList(body: GetListParams) {
    return api.get('/api/agent/get', body, {
      showError: false
    })
  },
}
export default auditNewApi