// 登录页
import React, { useCallback, useState } from 'react'
import styles from './login.module.scss'
import { isPhone, Storage } from '@bihu/common-js'
import { Button, Form, Toast } from 'antd-mobile'
import { useAppDispatch } from '@/store/hooks'
import { setIsLoggedIn } from '@/store/user'
import { TOKEN } from '@/constants/storage'
import activityApi from '@/apis/activity'
import NumberInput from '@/components/number-input/number-input'
import GetVerifyCode from '@/components/get-verify-code/get-verify-code'
import {
  LoginParams
} from '@/types/apis/user'
import classNames from 'classnames'
import getHostNameObj from '@/hooks/get-host-name-obj'

interface Props {}

const Login: React.FC<Props> = () => {

  const dispatch = useAppDispatch()
  const hostNameObj = getHostNameObj()

  const [form] = Form.useForm()
  const phone = Form.useWatch('phone', form)
  const verifyCode = Form.useWatch('verifyCode', form)
  // 渠道编码
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [adChannelCode, setAdChannelCode] = useState('')

  // 检验手机号码
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkMobile = (_: any, value: string) => {
    if (phone) {
      if (!isPhone(phone)) {
        return Promise.reject(new Error('手机号码格式有误'))
      }
      return Promise.resolve()
    }
    return Promise.reject(new Error('请输入手机号码'))
  }

  // 检验验证码
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkCode = (_: any, value: string) => {

    if (value) {
      // eslint-disable-next-line no-magic-numbers
      if (value.length !== 6) {
        return Promise.reject(new Error('验证码长度为6'))
      }
      return Promise.resolve()
    }
    return Promise.reject(new Error('请输入验证码'))
  }

  // 立即提交
  const onFinish = async(values: LoginParams) => {
    if (!checked) {
      return Toast.show('请勾选注册及隐私协议')
    }
    Toast.show({
      icon: 'loading',
      content: '登录中…',
      duration: 10000,
      maskClickable: false,
    })

    const res = await activityApi.login({
      mobile: phone,
      code: verifyCode,
      adChannelCode
    })
    Storage.set(TOKEN, res.token)
    dispatch(setIsLoggedIn(true))
    Toast.clear()

    window.location.replace('/')
  }

  // 表单操作按钮
  const formBtnBox = (<>
    <div className={styles.btnBox}>
      <Button type="submit" className={styles.btn} color="primary" disabled={!phone || !verifyCode}>登录</Button>
    </div>
  </>)

  // 发送验证码
  const sendHandle = async() => {
    await activityApi.smsSend({ mobile: phone })
    Toast.show({
      content: '发送成功，请注意查收',
    })
  }

  // 跳转页面
  const openView = (path: string) => {
    window.location.href = `/${path}-agreement?isShowHeader=true`
  }

  // 选择协议
  const [checked, setChecked] = useState<boolean>(false)
  // 切换选中协议
  const checkedToggle = useCallback(() => {
    setChecked(!checked)
  }, [checked])

  return (
    <div className={styles.login}>
      <header className={styles.header}>
        <div className={styles.tip}>欢迎登录</div>
        <div className={styles.title}>{hostNameObj.appName}</div>
      </header>

      <div className={styles.formBox}>
        <Form
          form={form}
          name="form"
          onFinish={onFinish}
          footer={formBtnBox}
          className={styles.form}
          initialValues={{
            phone: '',
            verifyCode: ''
          }}
        >
          <Form.Item name="phone" label="" validateTrigger="onBlur" rules={[
            {
              required: true,
              validator: checkMobile,
            }
          ]}>
            <NumberInput
              placeholder="请输入手机号"
              numberFormat={{
                decimal: false,
                negative: false,
                leadingZero: false,
                maxLength: 11
              }}
            />
          </Form.Item>

          <div className={styles.row}>
            <Form.Item name="verifyCode" label="" validateTrigger="onBlur" rules={[
              {
                required: true,
                validator: checkCode,
              }
            ]}>
              <NumberInput
                placeholder="请输入验证码"
                numberFormat={{
                  decimal: false,
                  negative: false,
                  maxLength: 6
                }}
              />
            </Form.Item>

            <GetVerifyCode
              className={styles.codeBox}
              phone={phone}
              sendHandle={sendHandle}
            />
          </div>
        </Form>
        <div className={styles.agreement}>
          <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedToggle}></i>
              我已阅读并同意
          <span
            className={styles.bold}
            onClick={() => {
              openView('register')
            }}
          >
            《注册协议》
          </span>
          <span className={styles.bold} onClick={() => {
            openView('privacy')
          }}>《隐私协议》</span>
        </div>

      </div>
    </div>
  )
}

export default Login
