// 获取验证码
import { isPhone } from '@bihu/common-js'
import { Button, Toast } from 'antd-mobile'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import styles from './get-verify-code.module.scss'

export interface GetVerifyCodeProps extends React.HTMLAttributes<HTMLElement> {
  phone: string // 手机号
  timeCount?: number // 倒计时时间
  sendHandle: () => void, // 发送操作
}
const DEFAULT_TIME = 60
const GetVerifyCode: React.FC<GetVerifyCodeProps> = props => {
  const {
    phone,
    timeCount = DEFAULT_TIME,
    className,
    sendHandle
  } = props


  const [count, setCount] = useState(timeCount)
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const cutCount = () => {
    setCount(prevState => prevState - 1)
  }

  useEffect(() => {
    if (count === 0) {
      timerRef.current && clearInterval(timerRef.current)
      setCount(timeCount)
    }
  }, [count, timeCount])

  // 发起验证码
  function sendCode() {
    if (!phone) {
      return Toast.show({
        content: '请输入手机号',
      })
    }

    if (!isPhone(phone)) {
      return Toast.show({
        content: '手机号码格式不正确',
      })
    }

    sendHandle && sendHandle()
    // 执行倒计时
    cutCount()
    timerRef.current = setInterval(cutCount, 1000)
  }

  return (
    <Button
      className={classNames(styles.getVerifyCodeBtn, className)}
      fill="outline"
      color="primary"
      disabled={count < DEFAULT_TIME}
      onClick={sendCode}
    >
      {count === DEFAULT_TIME ? '获取验证码' : `剩余 ${count}s`}
    </Button>
  )
}

export default GetVerifyCode
